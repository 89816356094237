// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".homeNews_2P1UU{position:relative;background-color:#fff;width:100%;z-index:2}.homeNews_2P1UU .headline_qSG38{margin:0 auto;padding:160px 30px 0;box-sizing:content-box}@media only screen and (min-width:600px){.homeNews_2P1UU .headline_qSG38{padding-left:45px;padding-right:45px}}@media only screen and (min-width:800px){.homeNews_2P1UU .headline_qSG38{padding-left:25px;padding-right:25px}}.homeNewsInner_iMzjP{margin:auto;max-width:1300px}@media only screen and (min-width:800px){.homeNewsInner_iMzjP{padding:0 30px}}@media only screen and (min-width:1150px){.homeNewsInner_iMzjP{padding:0 50px}}.blockBorder_1UAvW,.blockBorderBottom_1lecp{background-color:#b5b5b4;margin-top:20px;height:1px}.blockBorderBottom_1lecp{margin:0 30px}@media only screen and (min-width:950px){.blockBorderBottom_1lecp{background-color:transparent}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breakpointTiny": "350px",
	"breakpointSmall": "450px",
	"breakpointMedium": "600px",
	"breakpointLarge": "800px",
	"breakpointLarger": "950px",
	"breakpointFull": "1150px",
	"breakpointHigh": "1350px",
	"breakpointCinema": "1800px",
	"homeNews": "homeNews_2P1UU",
	"headline": "headline_qSG38",
	"homeNewsInner": "homeNewsInner_iMzjP",
	"blockBorder": "blockBorder_1UAvW",
	"blockBorderBottom": "blockBorderBottom_1lecp"
};
module.exports = ___CSS_LOADER_EXPORT___;
