<template>
  <div>
    <InfoPanel :class="$style.panel" :theme="INFO_PANEL_THEMES.info">
      {{ $t('infoRentalIncome') }}

      <SheetDownloadButton
        :filename="$t('sheetFilename')"
        url="//production-acces-impot-assets.s3.us-east-2.amazonaws.com/acces-impot_info-immeuble-revenus.pdf"
        :buttonText="$t('sheetButton')"
      />
    </InfoPanel>
  </div>
</template>

<script>
import Accordion from '@/components/Accordion.vue'
import InfoPanel, { INFO_PANEL_THEMES } from '@/components/InfoPanel.vue'
import SheetDownloadButton from './SheetDownloadButton.vue'

export default {
  name: 'InfoRentalIncome',
  components: {
    Accordion,
    InfoPanel,
    SheetDownloadButton,
  },

  props: {
    value: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      INFO_PANEL_THEMES,
    }
  },
}
</script>

<style lang="scss" module>
@import '../report-settings';

.panel {
  margin-top: $field-label-margin-top;
}
</style>

<i18n src="@/locales/info-rental-income.json" />
