<template>
  <div :class="$style.homeNews">
    <TransitionOnScroll :translateY="30" :class="$style.homeNewsInner">
      <Heading :class="$style.headline" :tag="HEADINGS.h1">
        {{ $t('headline') }}

        <div :class="$style.blockBorder"></div>
      </Heading>

      <div>
        <component
          :is="isCarouselActive ? 'Carousel' : 'div'"
          v-bind="isCarouselActive ? { options: carouselOptions, hasPagination: false } : {}"
        >
          <HomeNewsBlock
            v-for="(newsEntry, index) in newsEntries"
            :key="newsEntry.id"
            :id="newsEntry.id"
            :title="newsEntry.title"
            :text="newsEntry.text"
            :buttonText="newsEntry.buttonText"
            :buttonLink="newsEntry.buttonLink"
            :imgSrc="newsEntry.imgSrc"
            :isCarouselActive="isCarouselActive"
            :data-slide-index="index"
          />
        </component>
      </div>

      <div :class="$style.blockBorderBottom"></div>
    </TransitionOnScroll>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Heading, { HEADINGS } from '@/components/Heading/Heading.vue'
import Carousel from '@/components/Carousel/Carousel.vue'
import TransitionOnScroll from '@/components/TransitionOnScroll.vue'
import HomeNewsBlock from './HomeNewsBlock.vue'

const NEWS_COUNT = 2

export default {
  name: 'HomeNews',

  components: {
    Heading,
    Carousel,
    HomeNewsBlock,
    TransitionOnScroll,
  },

  data() {
    return {
      HEADINGS,
      NEWS_COUNT,
    }
  },

  computed: {
    ...mapGetters({
      isMqLarger: 'viewport/isMqLarger',
    }),

    isCarouselActive() {
      return !this.isMqLarger
    },

    newsEntries() {
      return Array.from(Array(NEWS_COUNT)).map((_, i) => {
        const entryId = `news-${i}`

        const buttonTextKey = `${entryId}.buttonText`
        const translatedButtonText = this.$t(buttonTextKey)
        const buttonText =
          translatedButtonText !== buttonTextKey ? translatedButtonText : this.$t('learnMore')

        return {
          id: entryId,
          title: this.$t(`${entryId}.title`),
          text: this.$t(`${entryId}.text`),
          buttonText,
          buttonLink: this.$t(`${entryId}.buttonLink`),
          imgSrc: this.$t(`${entryId}.imgSrc`),
        }
      })
    },

    carouselOptions() {
      const hasMoreThanTwoSlides = NEWS_COUNT > 2

      return {
        slidesPerView: hasMoreThanTwoSlides ? 1.25 : 1.2,
        slidesOffsetBefore: hasMoreThanTwoSlides ? 0 : 20,
        centeredSlides: hasMoreThanTwoSlides,

        breakpoints: {
          [parseInt(this.$style.breakpointMedium)]: {
            slidesPerView: hasMoreThanTwoSlides ? 1.3 : 1.4,
            slidesOffsetBefore: hasMoreThanTwoSlides ? 0 : 20,
          },
          [parseInt(this.$style.breakpointLarge)]: {
            slidesPerView: hasMoreThanTwoSlides ? 1.5 : 2,
            slidesOffsetBefore: 0,
            enabled: hasMoreThanTwoSlides,
          },
        },
      }
    },
  },
}
</script>

<style lang="scss" module>
:export {
  breakpointTiny: $mq-tiny;
  breakpointSmall: $mq-small;
  breakpointMedium: $mq-medium;
  breakpointLarge: $mq-large;
  breakpointLarger: $mq-larger;
  breakpointFull: $mq-full;
  breakpointHigh: $mq-high;
  breakpointCinema: $mq-cinema;
}

$section-padding-x: 30px;
$section-padding-x-medium: 45px;
$section-padding-x-large: 25px;

.homeNews {
  position: relative;
  background-color: $color-white;
  width: 100%;
  z-index: 2;

  // to overwrite Heading margin
  .headline {
    margin: 0 auto;
    padding: 160px $section-padding-x 0;
    box-sizing: content-box;

    @include min-screen($mq-medium) {
      padding-left: $section-padding-x-medium;
      padding-right: $section-padding-x-medium;
    }

    @include min-screen($mq-large) {
      padding-left: $section-padding-x-large;
      padding-right: $section-padding-x-large;
    }
  }
}

.homeNewsInner {
  margin: auto;
  max-width: 1300px;

  @include min-screen($mq-large) {
    padding: 0 30px;
  }

  @include min-screen($mq-full) {
    padding: 0 50px;
  }
}

.blockBorder,
.blockBorderBottom {
  background-color: $color-light-grey-1-5;
  margin-top: 20px;
  height: 1px;
}

.blockBorderBottom {
  margin: 0 $section-padding-x;

  @include min-screen($mq-larger) {
    background-color: transparent;
  }
}
</style>

<i18n src="@/locales/home-news.json" />
