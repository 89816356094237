<template>
  <div>
    <LinkDownload :class="$style.downloadButton" :filename="filename" :url="url">
      <IconButton>
        <template #iconBefore>
          <IconDocument :class="$style.iconDocument" />
        </template>
        {{ buttonText }}</IconButton
      >
    </LinkDownload>
  </div>
</template>

<script>
import LinkDownload from '@/components/LinkDownload.vue'
import IconButton from '@/components/IconButton.vue'
import IconDocument from '@/components/_icons/IconDocument.svg'

export default {
  name: 'SheetDownloadButton',
  components: {
    LinkDownload,
    IconButton,
    IconDocument,
  },

  props: {
    filename: {
      type: String,
      default: '',
    },

    url: {
      type: String,
      default: '',
    },

    buttonText: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" module>
.downloadButton {
  display: inline-block;
  margin: 16px 0 4px;
}

.iconDocument {
  display: inline-block;
  width: 20px;
  color: $color-light-grey-1;
  transform: translateY(-1px);
}
</style>
