const { personTypes } = require('acces-impot-settings-report')

const CA_ADULT_PRICE = 79.95

const ADULT_TAX_REPORT_TRANSLATIONS = {
  en: 'Tax report for adult',
  fr: 'Déclaration pour adulte',
}

const PRODUCTS = {
  report: {
    [personTypes.self]: {
      name: ADULT_TAX_REPORT_TRANSLATIONS,
    },
    [personTypes.partner]: {
      name: ADULT_TAX_REPORT_TRANSLATIONS,
    },
    [personTypes.child]: {
      name: {
        en: 'Tax report for child',
        fr: 'Déclaration pour enfant',
      },
    },
    rentalIncome: {
      name: {
        en: 'Income building',
        fr: 'Immeuble à revenus',
      },
    },
  },
}

const PRICES = {
  ca: {
    report: {
      [personTypes.self]: CA_ADULT_PRICE,
      [personTypes.partner]: CA_ADULT_PRICE,
      [personTypes.child]: 39.95,
      selfEmployed: 159.95,
      rentalIncome: 20,
    },
    taxes: [
      {
        id: 'gst',
        name: {
          en: 'GST',
          fr: 'TPS',
        },
        rate: 0.05,
      },
      {
        id: 'qst',
        name: {
          en: 'QST',
          fr: 'TVQ',
        },
        rate: 0.09975,
      },
    ],
  },
}

const PAYMENT_METHODS = {
  creditCard: 'creditCard',
  paypal: 'paypal',
}

function getDiscountFromPromoCode(subtotal, promoCode) {
  if (!subtotal || !promoCode) return 0

  const { amountDiscount, percentageDiscount } = promoCode
  let discount = 0
  if (amountDiscount) discount = amountDiscount
  else if (percentageDiscount) discount = Math.round(subtotal * percentageDiscount * 100) / 100

  return discount
}

module.exports = {
  PRODUCTS,
  PRICES,
  PAYMENT_METHODS,
  getDiscountFromPromoCode,
}
