import { retry } from '@/helpers/request'

export * from './gtag'
export * from './facebook'

export const TRACK_CATEGORIES = {
  account: 'account',
  contact: 'contact',
  ecommerce: 'ecommerce',
  navigation: 'navigation',
  news: 'news',
  survey: 'survey',
}

export const TRACK_CONTEXTS = {
  authenticationPage: 'authentication-page',
  contactPage: 'contact-page',
  footer: 'footer',
  homepageClick: 'homepage:click',
  liveChat: 'live-chat',
  mobileMenu: 'mobile-menu',
}

export const TRACK_ACTIONS = {
  confirmed: 'confirmed',
  open: 'open',
  purchase: 'purchase',
  routeView: 'route-view',
  signup: 'signup',
  answer: 'answer',
}

export async function track(category, action, payload) {
  const { default: handler } = await retry(
    () => import(/* webpackChunkName: "[request]" */ `./handlers/${category}.js`)
  )
  handler[action](payload)
}
