<template>
  <div class="flash">
    <div class="flash__inner">
      <FlashMessage
        v-for="message in messages"
        :id="message.id"
        :key="message.id"
        :messageKey="message.key"
        :identifier="message.identifier"
        :text="message.text"
        :type="message.type"
        :iconName="message.iconName"
        :cookieLifetimeInDays="message.cookieLifetimeInDays"
        :isAutoHiding="message.isAutoHiding"
        :autoHideAfter="message.autoHideAfter"
        :removeMessage="removeMessage"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import FlashMessage from './FlashMessage.vue'

export default {
  name: 'FlashMessages',
  components: {
    FlashMessage,
  },

  computed: {
    ...mapState('flash', ['messages']),
  },

  methods: {
    ...mapActions({
      removeMessage: 'flash/removeMessage',
    }),
  },
}
</script>

<style scoped lang="scss">
.flash {
  position: fixed;
  top: $site-header-height-small;
  width: 100%;
  z-index: $site-header-z-index - 2;

  &__inner {
    display: flex;
    flex-direction: column-reverse; // to fix z indexes on multiple messages
  }

  @include min-screen($mq-large) {
    top: $site-header-height;
  }
}
</style>
