var render = function () {
var _obj, _obj$1, _obj$2;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.mainContainer},[_c('Slant',{class:_vm.$style.slant,attrs:{"color":_vm.$style.colorSlant}},[_c('div',{class:_vm.$style.mainContainerInner},[_c('div',{class:_vm.$style.columnText},[_c('div',{class:_vm.$style.headlineContainer},[_c('TransitionOnScroll',{attrs:{"translateY":_vm.isMqLarger ? 20 : 10,"rootMargin":"0px"},on:{"intersecting":function($event){_vm.isTextIntersecting = $event}}},[_c('BeforeHeading',{class:_vm.$style.beforeTitle},[_vm._v(_vm._s(_vm.$t('beforeTitle')))])],1),_c('TransitionOnScroll',{attrs:{"translateY":_vm.isMqLarger ? 35 : 15,"rootMargin":"0px","isIntersecting":_vm.isTextIntersecting}},[_c('Heading',{attrs:{"tag":_vm.HEADINGS.h1}},[_vm._v(" "+_vm._s(_vm.$t('title'))+" ")])],1),_c('TransitionOnScroll',{attrs:{"translateY":_vm.isMqLarger ? 60 : 28,"rootMargin":"0px","isIntersecting":_vm.isTextIntersecting}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('heroText'))}})]),_c('TransitionOnScroll',{class:_vm.$style.heroButtonWrap,attrs:{"translateY":_vm.isMqLarger ? 90 : 50,"rootMargin":"0px","isIntersecting":_vm.isTextIntersecting}},[_c('Button',{attrs:{"shape":_vm.BUTTON_STYLES.shape.spade,"to":_vm.localeRoute({
                  name: _vm.ROUTE_NAMES.auth,
                  params: { type: _vm.AUTH_TYPES.signup },
                })}},[_vm._v("\n              "+_vm._s(_vm.$t('form.start'))+"\n            ")])],1)],1)]),_c('div',{class:_vm.$style.columnImg},[_c('div',{class:_vm.$style.imageContainer},[_c('div',{class:_vm.$style.playContainer},[_c('div',{class:[
                _vm.$style.playCircleHover,
                ( _obj = {}, _obj[_vm.$style.playCircleHoverVisible] = _vm.isPlayButtonIntersecting, _obj ) ],on:{"click":_vm.showOverlayHomeVideo}}),_c('div',{class:_vm.$style.playCircle},[_c('TransitionOnScroll',{class:_vm.$style.playCircleBefore,attrs:{"translateY":0,"scale":0.8,"rootMargin":"0px 0px -5% 0px"},on:{"intersecting":function($event){_vm.isPlayButtonIntersecting = $event}}}),_c('TransitionOnScroll',{class:_vm.$style.playLabelContainer,attrs:{"transformStart":((_vm.$style.playLabelContainerTranslate) + " rotate(-30deg)"),"isIntersecting":_vm.isPlayButtonIntersecting}},_vm._l((_vm.playLabelSettings.styles),function(charStyle,index){return _c('span',{key:("char-" + index),class:_vm.$style.playChar,style:({
                    transform: ("rotate(" + (charStyle.radius) + "rad)"),
                    width: ((charStyle.width) + "px"),
                    height: ((charStyle.height) + "px"),
                    left: ((charStyle.left) + "px"),
                    top: ((charStyle.top) + "px"),
                  })},[_vm._v(_vm._s(_vm.playLabelSettings.label[index]))])}),0),_c('IconPlay',{class:[_vm.$style.playIcon, ( _obj$1 = {}, _obj$1[_vm.$style.playIconVisible] = _vm.isPlayButtonIntersecting, _obj$1 )]})],1)]),_c('div',{class:_vm.$style.imageContainerInner},[_c('TransitionOnScroll',{class:[_vm.$style.imageBackground, ( _obj$2 = {}, _obj$2[_vm.$style.intersecting] = _vm.isImgBgIntersecting, _obj$2 )],attrs:{"translateY":0,"rootMargin":"0px 0px -5% 0px"},on:{"intersecting":function($event){_vm.isImgBgIntersecting = $event}}}),_c('IconHeroShape',{class:_vm.$style.imageShape})],1)])])])]),_c('div',{class:_vm.$style.productionTimeContainer},[_c('TransitionOnScroll',{class:_vm.$style.productionTimeContainerInner,attrs:{"translateY":40,"rootMargin":_vm.isMqLarger ? '0px' : '0px 0px -10% 0px'}},[_c('div',{class:_vm.$style.productionTimeTitle},[_vm._v(_vm._s(_vm.$t('productionTime')))]),_c('div',{class:_vm.$style.productionTimeBlock},[_vm._v(_vm._s(_vm.productionTime[0]))]),_c('div',{class:_vm.$style.productionTimeBlock},[_vm._v(_vm._s(_vm.productionTime[1]))]),_c('div',{class:_vm.$style.productionTimeSubTitle},[_vm._v(_vm._s(_vm.$t('days')))])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }