<template>
  <component :is="isCarouselActive ? 'Slide' : 'div'" :class="$style.slide">
    <div :class="$style.imgSection">
      <div :class="$style.imgWrap">
        <img :class="$style.img" :src="imgSrc" loading="lazy" draggable="false" />
      </div>
    </div>

    <div :class="$style.textSection">
      <Heading :class="$style.title" :tag="HEADINGS.h3">{{ title }}</Heading>
      <p :class="$style.text">{{ text }}</p>

      <div :class="$style.buttonWrap">
        <Button
          tag="a"
          :shape="BUTTON_STYLES.shape.spade"
          :size="BUTTON_STYLES.size.small"
          :href="buttonLink"
          target="_blank"
          @click="trackButtonClick"
          >{{ buttonText }}</Button
        >
      </div>
    </div>
  </component>
</template>

<script>
import { track, TRACK_CATEGORIES, TRACK_CONTEXTS } from '@/services/tracking'
import { Slide } from '@/components/Carousel/Carousel.vue'
import Heading, { HEADINGS } from '@/components/Heading/Heading.vue'
import Button, { BUTTON_STYLES } from '@/components/Form/Button.vue'

const TRACKING_THROTTLE_TIME = 500

export default {
  name: 'HomeNewsBlock',

  components: {
    Slide,
    Heading,
    Button,
  },

  props: {
    id: {
      type: String,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },

    text: {
      type: String,
      required: true,
    },

    buttonText: {
      type: String,
      required: true,
    },

    buttonLink: {
      type: String,
      required: true,
    },

    imgSrc: {
      type: String,
      required: true,
    },

    isCarouselActive: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      BUTTON_STYLES,
      HEADINGS,
      isTrackingEnabled: true,
    }
  },

  methods: {
    trackButtonClick() {
      if (!this.isTrackingEnabled) return

      track(TRACK_CATEGORIES.news, TRACK_CONTEXTS.homepageClick, { id: this.id })

      // Throttle
      this.isTrackingEnabled = false
      setTimeout(() => (this.isTrackingEnabled = true), TRACKING_THROTTLE_TIME)
    },
  },
}
</script>

<style lang="scss" module>
$block-padding-x-small: 20px;
$block-padding-x-medium: 25px;

.slide {
  margin: 35px 0;

  @include min-screen($mq-larger) {
    display: grid;
    grid-template-columns: Min(25vw, 330px) 1fr;
    gap: 50px;
    margin: 35px $block-padding-x-medium;
    padding-bottom: 35px;
    border-bottom: 1px solid $color-light-grey-1-5;
  }
}

.imgSection,
.title,
.text,
.buttonWrap {
  padding: 0 $block-padding-x-small;

  @include min-screen($mq-medium) {
    padding: 0 $block-padding-x-medium;
  }

  @include min-screen($mq-larger) {
    padding: 0;
  }
}

.imgSection {
  margin-bottom: 25px;

  @include min-screen($mq-larger) {
    margin-bottom: 0;
    padding-left: 0;
  }
}

.imgWrap {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 62%;
  font-size: 0;
  border-radius: 8px;

  @include min-screen($mq-larger) {
    height: 80%;
  }

  @include min-screen($mq-full) {
    height: 100%;
  }
}

.img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  user-select: none;
}

.text {
  margin-top: 15px;
  margin-bottom: 20px;
  color: $color-brand-navy;
}

.buttonWrap {
  margin-top: 25px;
}
</style>
