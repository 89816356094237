<template>
  <Slant :class="$style.homeSteps" :color="$style.colorSlant">
    <TransitionOnScroll :translateY="30">
      <Heading :class="$style.headline" :tag="HEADINGS.h3">
        <LineBreakResolver :text="$t('headline')" />
      </Heading>
    </TransitionOnScroll>
    <div :class="$style.stepBlockWrap">
      <TransitionOnScroll
        v-for="(step, index) in steps"
        :key="`step-${index}`"
        :class="[$style.stepBlock, $style[step.bgClass]]"
        rootMargin="0px 0px -5% 0px"
        :translateY="[30, 50, 80][isMqLarger ? index : 0]"
        :isIntersecting="isMqLarger && index > 0 ? isIntersecting : undefined"
        v-on="index === 0 ? { intersecting: updateIsIntersecting } : {}"
      >
        <component :is="step.icon" :class="[$style.stepIcon, $style[`stepIcon--${index + 1}`]]" />
        <div :class="$style.stepIndex">
          <span :class="$style.stepIndexNumber">{{ index + 1 }}</span>
        </div>
        <Heading :class="$style.stepTitle" :tag="HEADINGS.h3">{{ step.title }}</Heading>
        <div :class="[$style.stepText, $style[`stepText--${index + 1}`]]">{{ step.text }}</div>
      </TransitionOnScroll>
    </div>
  </Slant>
</template>

<script>
import { mapGetters } from 'vuex'
import Slant from '@/components/Slant/Slant.vue'
import Heading, { HEADINGS } from '@/components/Heading/Heading.vue'
import LineBreakResolver from '@/components/LineBreakResolver.vue'
import TransitionOnScroll from '@/components/TransitionOnScroll.vue'
import IconCreateAccount from '@/components/_icons/IconCreateAccount.svg'
import IconPersonalQuestions from '@/components/_icons/IconPersonalQuestions.svg'
import IconUploadDocuments from '@/components/_icons/IconUploadDocuments.svg'

export default {
  name: 'HomeSteps',
  components: {
    Slant,
    Heading,
    LineBreakResolver,
    TransitionOnScroll,
  },

  data() {
    return {
      HEADINGS,
      isIntersecting: false,
    }
  },

  computed: {
    ...mapGetters({
      isMqLarger: 'viewport/isMqLarger',
    }),

    steps() {
      return [
        {
          icon: IconCreateAccount,
          bgClass: 'bgYellow',
          title: this.$t('profile.title'),
          text: this.$t('profile.text'),
        },
        {
          icon: IconPersonalQuestions,
          bgClass: 'bgGreen',
          title: this.$t('questions.title'),
          text: this.$t('questions.text'),
        },
        {
          icon: IconUploadDocuments,
          bgClass: 'bgBlue',
          title: this.$t('documents.title'),
          text: this.$t('documents.text'),
        },
      ]
    },
  },

  methods: {
    updateIsIntersecting(isIntersecting) {
      this.isIntersecting = isIntersecting
    },
  },
}
</script>

<style lang="scss" module>
@import '@/components/Slant/slant-settings.scss';

$icon-width: 194px;
$icon1-ratio: 840 / 840;
$icon2-ratio: 840 / 823;
$icon3-ratio: 805 / 778;

:export {
  colorSlant: $color-brand-sand-bg-light;
}

.homeSteps {
  background-color: $color-white;
  text-align: center;
  width: 100%;
  padding-bottom: calc(100vw * #{$slant-ratio} + 80px);

  // to overwrite Heading margin
  .headline {
    max-width: 700px;
    margin: 0 auto;
    padding: 100px 30px 60px;
    box-sizing: content-box;

    @include min-screen($mq-small) {
      padding-left: 60px;
      padding-right: 60px;
    }

    @include min-screen($mq-large) {
      padding: calc(2vw + 90px) 80px 60px;
    }

    @include min-screen($mq-larger) {
      padding-bottom: 70px;
    }

    @include min-screen($mq-full) {
      padding-top: 100px;
    }
  }
}

.stepBlockWrap {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 20px;

  @include min-screen($mq-larger) {
    flex-wrap: nowrap;
  }
}

.stepBlock {
  position: relative;
  margin: 170px 20px 20px;
  width: 280px;
  border-radius: 6px;
  color: $color-brand-navy;

  @include min-screen($mq-medium) {
    flex: 0 0 50%;
    max-width: 280px;
  }

  @include min-screen($mq-larger) {
    flex: 1;
  }
}

.bgYellow {
  background-color: $color-brand-yellow-light;
}

.bgGreen {
  background-color: $color-brand-green-light;
}

.bgBlue {
  background-color: $color-brand-blue-grey;
}

.stepIcon {
  position: absolute;
  top: 35px;
  left: calc(50% + 4px);
  width: $icon-width;
  transform: translate(-50%, -100%);

  &--1 {
    top: 45px;
    height: round($icon-width * $icon1-ratio);
  }

  &--2 {
    height: round($icon-width * $icon2-ratio);
  }

  &--3 {
    height: round($icon-width * $icon3-ratio);
  }
}

.stepIndex {
  display: flex;
  position: absolute;
  top: 72px;
  left: 50%;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  border: 2px solid $color-white;
  box-sizing: content-box;
  transform: translateX(-50%);
}

.stepIndexNumber {
  margin: auto;
  line-height: 1;
  font-weight: bold;
  font-size: 36px;
  color: $color-white;
}

.stepBlock {
  .stepTitle {
    margin: 160px 0 20px;
    padding: 0 25px;
  }
}

.stepText {
  height: 140px;
  padding: 0 30px 30px;
  box-sizing: content-box;

  &--1 {
    padding-left: 35px;
    padding-right: 35px;
  }
}
</style>

<i18n src="@/locales/home-steps.json" />
