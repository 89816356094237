const {
  REPORT_STATUSES,
  PERSON_TYPES,
  GENDERS,
  DOCUMENT_TO_APPROVE_URL_PARAM,
  INVESTMENT_PLATFORM_INTEREST_LEVELS,
  PREFERRED_CONTACT_METHODS,
} = require('acces-impot-utils/lib/report')
const { DOCUMENT_TYPES } = require('./constants/document')
const documentHelpers = require('./helpers/document')

const APPROXIMATE_INCOMES = {
  noIncome: 'noIncome',
  income0to25: 'income0to25',
  income25to50: 'income25to50',
  income50andMore: 'income50andMore',
}

module.exports = {
  ...documentHelpers,

  PAYMENT_METHOD_URL_PARAM: 'paymentMethod',
  DOCUMENT_TO_APPROVE_URL_PARAM,
  SUCCESSFUL_PAYMENT_URL_PARAM: 'orderSuccess',
  SUCCESSFUL_SIGNATURE_URL_PARAM: 'signed',
  SUCCESSFUL_SIGNATURE_URL_PARAM_SEPARATOR: '_',

  personTypes: PERSON_TYPES,
  PERSON_TYPES,

  genders: GENDERS,
  GENDERS,

  civilStatuses: {
    single: 'single',
    commonLawPartner: 'commonLawPartner',
    married: 'married',
    widowed: 'widowed',
    separated: 'separated',
    divorced: 'divorced',
  },
  provinces: {
    ca: {
      ab: 'ab',
      bc: 'bc',
      mb: 'mb',
      nb: 'nb',
      nl: 'nl',
      ns: 'ns',
      nt: 'nt',
      nu: 'nu',
      on: 'on',
      pe: 'pe',
      qc: 'qc',
      sk: 'sk',
      yt: 'yt',
    },
  },
  nonCanadianSituations: {
    permanentResident: 'permanentResident',
    temporaryResident: 'temporaryResident',
    temporaryWorker: 'temporaryWorker',
    foreignStudent: 'foreignStudent',
    nonResident: 'nonResident',
  },
  APPROXIMATE_INCOMES,

  questionCategories: {
    drugInsurance: 'drugInsurance',
    solidarityTaxCredit: 'solidarityTaxCredit',
    incomeSources: 'incomeSources',
    rrsp: 'rrsp',
    children: 'children',
    student: 'student',
    taxCredits: 'taxCredits',
    generalQuestions: 'generalQuestions',
    otherInformation: 'otherInformation',
  },
  documentTypes: DOCUMENT_TYPES,
  drugInsuranceCoverages: {
    groupInsurance: 'groupInsurance',
    insuranceFromFamily: 'insuranceFromFamily',
    publicInsurance: 'publicInsurance',
    notEntitledToDrugInsurance: 'notEntitledToDrugInsurance',
  },
  deprecatedDrugInsuranceParticularSituations: {
    minorUnmarried: 'minorUnmarried',
    fullStudent1825: 'fullStudent1825',
    jamesBayAgreement: 'jamesBayAgreement',
    freeMed65: 'freeMed65',
    bookFree65: 'bookFree65',
    validBooklet: 'validBooklet',
    chsld: 'chsld',
    impairmentB18yo: 'impairmentB18yo',
    foreignNational: 'foreignNational',
    resOutsideBusinessQc: 'resOutsideBusinessQc',
  },
  drugInsuranceParticularSituations: {
    no: 'no',
    socialAssistance: 'socialAssistance',
    indianOrInuit: 'indianOrInuit',
    stayedOutsideQc: 'stayedOutsideQc',
    frenchStudent: 'frenchStudent',
  },
  solidarityTaxCreditWhoAsk: {
    yourself: 'yourself',
    partner: 'partner',
  },
  solidarityTaxCreditOwnOrRent: {
    own: 'own',
    rent: 'rent',
    none: 'none',
  },
  incomeSources: {
    none: 'none',
    employment: 'employment',
    rentalBuilding: 'rentalBuilding',
    retirement: 'retirement',
    investment: 'investment',
    stocksSecuritiesCryptocurrencies: 'stocksSecuritiesCryptocurrencies',
    socialAssistance: 'socialAssistance',
    cnesst: 'cnesst',
    ivac: 'ivac',
    employmentInsurance: 'employmentInsurance',
    qpip: 'qpip',
    taxableSalaryInsurance: 'taxableSalaryInsurance',
    saaq: 'saaq',
    trustIncome: 'trustIncome',
    cerb: 'cerb', // pcu
    other: 'other',
  },
  taxCredits: {
    no: 'no',
    disabledPerson: 'disabledPerson',
    homeSupport: 'homeSupport',
    familyCaregivers: 'familyCaregivers',
    remoteAreas: 'remoteAreas',
    movingExpenses: 'movingExpenses',
    renoVert: 'renoVert',
    homeAccessibility: 'homeAccessibility',
    volunteer: 'volunteer',
    teacherSchoolSupply: 'teacherSchoolSupply',
    employmentExpenses: 'employmentExpenses',
    recentGraduatesRemote: 'recentGraduatesRemote',
  },
  withdrawUnderHbpLlp: {
    no: 'no',
    underHbp: 'underHbp',
    underLlp: 'underLlp',
    underHbpAndLlp: 'underHbpAndLlp',
  },
  investmentPlatformInterestLevel: INVESTMENT_PLATFORM_INTEREST_LEVELS,
  preferredContactMethod: PREFERRED_CONTACT_METHODS,
  documentFileOptions: {
    additional: 'additional',
  },
  frequencies: {
    weekly: 'weekly',
    monthly: 'monthly',
    annually: 'annually',
  },
  fileStatuses: REPORT_STATUSES,
  REPORT_STATUSES,
}
