// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".infoPanel_1RpET{position:relative;padding:18px 18px 18px 52px;color:#666}@media only screen and (min-width:450px){.infoPanel_1RpET{padding:20px 20px 20px 60px}}.infoPanel_1RpET.small_1_Gb8{padding-top:13px;padding-bottom:13px}@media only screen and (min-width:450px){.infoPanel_1RpET.small_1_Gb8{padding-top:14px;padding-bottom:14px}}.infoPanel_1RpET.hasNoIcon_1WKlG{padding-left:18px}@media only screen and (min-width:450px){.infoPanel_1RpET.hasNoIcon_1WKlG{padding-left:20px}}.infoPanel_1RpET.darkColor_16lq7{color:#444}.info_1CGyv{background-color:#eaf1f5}.positive_1jv35{background-color:#eaf5f1}.warning_MOQzE{background-color:#fef8e8}.icon_3YjCb{position:absolute;opacity:.8}.info_1CGyv .icon_3YjCb,.warning_MOQzE .icon_3YjCb{width:32px;height:32px}.info_1CGyv .icon_3YjCb{top:14px;left:11px;color:#86aac0}@media only screen and (min-width:450px){.info_1CGyv .icon_3YjCb{top:17px;left:16px}}.info_1CGyv.small_1_Gb8 .icon_3YjCb{top:11px}@media only screen and (min-width:450px){.info_1CGyv.small_1_Gb8 .icon_3YjCb{top:12px}}.positive_1jv35 .icon_3YjCb{top:16px;left:13px;width:28px;height:28px;color:#6c9}@media only screen and (min-width:450px){.positive_1jv35 .icon_3YjCb{top:19px;left:18px}}.positive_1jv35.small_1_Gb8 .icon_3YjCb{top:13px}@media only screen and (min-width:450px){.positive_1jv35.small_1_Gb8 .icon_3YjCb{top:14px}}.warning_MOQzE .icon_3YjCb{top:12px;left:10px;color:#ffb52e}@media only screen and (min-width:450px){.warning_MOQzE .icon_3YjCb{top:15px;left:15px}}.warning_MOQzE.small_1_Gb8 .icon_3YjCb{top:11px}@media only screen and (min-width:450px){.warning_MOQzE.small_1_Gb8 .icon_3YjCb{top:12px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoPanel": "infoPanel_1RpET",
	"small": "small_1_Gb8",
	"hasNoIcon": "hasNoIcon_1WKlG",
	"darkColor": "darkColor_16lq7",
	"info": "info_1CGyv",
	"positive": "positive_1jv35",
	"warning": "warning_MOQzE",
	"icon": "icon_3YjCb"
};
module.exports = ___CSS_LOADER_EXPORT___;
