// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".downloadButton_1VbDE{display:inline-block;margin:16px 0 4px}.iconDocument_G0neH{display:inline-block;width:20px;color:#9d9d9c;transform:translateY(-1px)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"downloadButton": "downloadButton_1VbDE",
	"iconDocument": "iconDocument_G0neH"
};
module.exports = ___CSS_LOADER_EXPORT___;
