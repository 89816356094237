var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FieldWrapper',{staticClass:"checkbox",class:{ 'checkbox--enabled': !_vm.isComputedDisabled }},[_c('input',_vm._g({ref:"field",staticClass:"checkbox__field",class:{ 'checkbox__field--red': _vm.hasRedBorder },attrs:{"id":_vm.id,"type":"checkbox","disabled":_vm.isComputedDisabled},domProps:{"checked":_vm.model},on:{"change":_vm.updateModel}},_vm.on)),_c('div',{staticClass:"checkbox__container"},[_c('div',{staticClass:"checkbox__label-wrap"},[_c('label',{staticClass:"checkbox__label",class:{
          'checkbox__label--red': _vm.hasRedBorder,
          'checkbox__label--green-bg': _vm.hasGreenBackground,
        },attrs:{"for":_vm.id}},[_c('span',{staticClass:"checkbox__box",class:{
            'checkbox__box--green': _vm.hasGreenBackground,
            'checkbox__box--bold-border': _vm.hasBoldBorder,
            'checkbox__box--black-n-white': _vm.isBlackAndWhite,
          }},[_c('IconCheck',{staticClass:"checkbox__check",class:{
              'checkbox__check--visible': _vm.model || _vm.isShownAsChecked,
              'checkbox__check--big': _vm.hasBigCheck,
              'checkbox__check--white': _vm.hasGreenBackground,
            }})],1),_c('span',{staticClass:"checkbox__label-text"},[(_vm.$slots.label)?_vm._t("label"):[_vm._v(_vm._s(_vm.label))]],2)])]),_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }