<template>
  <div class="layout layout--default">
    <client-only>
      <Header />
      <FlashMessages />
      <OverlayLoader />
    </client-only>
    <SiteContainer> <Nuxt /> </SiteContainer>
    <Footer />
    <LiveChat />
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import {
  PERSON_TYPES,
  SUCCESSFUL_SIGNATURE_URL_PARAM,
  SUCCESSFUL_SIGNATURE_URL_PARAM_SEPARATOR,
} from 'acces-impot-settings-report'
import { MESSAGE_ON_LOAD_URL_PARAM, MESSAGE_ON_LOAD_IDS } from 'acces-impot-utils/lib/tracking'
import { waitFor } from '@/helpers/time'
import { generateSocialMetaTags } from '@/helpers/render'
import { personalizeTranslation } from '@/helpers/i18n'
import { getQueryFromUrl, removeQueryFromCurrentRoute } from '@/helpers/url'
import defaultOgImageSrc from '@/assets/images/couple-on-computer.jpg'
import Header from '@/components/Header/Header.vue'
import FlashMessages from '@/components/FlashMessage/FlashMessages.vue'
import OverlayLoader from '@/components/Overlay/OverlayLoader.vue'
import SiteContainer from '@/components/SiteContainer/SiteContainer.vue'
import Footer from '@/components/Footer.vue'
import LiveChat from '@/components/LiveChat/LiveChat.vue'

export default {
  name: 'DefaultLayout',
  head() {
    const title = this.$t('meta.title')
    const titleTemplate = `${this.$t('accesImpot')} | %s`
    const fullTitle = titleTemplate.replace('%s', title)
    const description = this.$t('meta.description')
    const headConfig = {
      title,
      titleTemplate,
      meta: generateSocialMetaTags({ title: fullTitle, description, image: defaultOgImageSrc }),
    }
    const nuxtI18nSeo = this.$nuxtI18nSeo()

    for (const prop in nuxtI18nSeo) {
      if (Array.isArray(nuxtI18nSeo[prop])) {
        headConfig[prop] = [...nuxtI18nSeo[prop], ...(headConfig[prop] || [])]
      } else if (typeof nuxtI18nSeo[prop] === 'object') {
        headConfig[prop] = { ...nuxtI18nSeo[prop], ...(headConfig[prop] || {}) }
      } else if (!headConfig[prop]) {
        headConfig[prop] = nuxtI18nSeo[prop]
      }
    }
    const canonicalLink = headConfig.link?.find(link => link.rel === 'canonical')
    if (canonicalLink?.href) {
      headConfig.meta.push({
        hid: 'og:url',
        property: 'og:url',
        content: canonicalLink.href,
      })
    }
    return headConfig
  },

  components: {
    Header,
    FlashMessages,
    OverlayLoader,
    SiteContainer,
    Footer,
    LiveChat,
  },

  computed: {
    ...mapState('page', ['isVisible']),
    ...mapState('report', ['isCurrentReportSet']),

    ...mapGetters({
      viewportWidth: 'viewport/width',
      personalDetailsOfFamilyMembersWithReport: 'report/personalDetailsOfFamilyMembersWithReport',
      isUserLoggedIn: 'account/isUserLoggedIn',
      isCurrentUserFetched: 'account/isCurrentUserFetched',
    }),
  },

  watch: {
    viewportWidth: {
      immediate: true,
      handler() {
        this.updatePageProperties()
      },
    },
  },

  mounted() {
    this.resetViewportStoreState()
    this.resetPageViewCount()

    waitFor(
      this,
      () =>
        this.isVisible &&
        this.isCurrentUserFetched &&
        (!this.isUserLoggedIn || this.isCurrentReportSet)
    ).then(() => {
      this.showSuccessMessageAfterSigningDocument()
      this.showWarnMessageAfterForceReload()
    })
  },

  methods: {
    ...mapMutations({
      resetPageViewCount: 'page/resetPageViewCount',
    }),

    ...mapActions({
      resetViewportStoreState: 'viewport/resetViewportStoreState',
      updatePageProperties: 'page/updatePageProperties',
      addFlashSuccessMessage: 'flash/addSuccessMessage',
      addFlashWarnMessage: 'flash/addWarnMessage',
    }),

    getFullNameFromPersonalDetails({ firstName, lastName }) {
      return `${firstName} ${lastName}`
    },

    getListText(array) {
      let str = ''
      let and = this.$t('and')
      if (this.$i18n.locale === 'en' && array.length > 2) and = `,${and}`

      array.forEach((element, index) => {
        const prefix = index === 0 ? '' : index === array.length - 1 ? and : ', '
        str += `${prefix}${element}`
      })
      return str
    },

    showSuccessMessageAfterSigningDocument() {
      const signedPersonKeysFromUrl = getQueryFromUrl(SUCCESSFUL_SIGNATURE_URL_PARAM)
      if (!signedPersonKeysFromUrl) return

      const signedPersonKeys = signedPersonKeysFromUrl.split(
        SUCCESSFUL_SIGNATURE_URL_PARAM_SEPARATOR
      )
      let message = ''

      if (!this.isUserLoggedIn) {
        message = this.$t('signatureSuccessNotLoggedIn')
      } else {
        const otherMembersWithReport = this.personalDetailsOfFamilyMembersWithReport.filter(
          personalDetails =>
            personalDetails.personType !== PERSON_TYPES.self &&
            !signedPersonKeys.includes(personalDetails.personKey)
        )
        if (otherMembersWithReport.length === 1) {
          const localeKey = 'signatureSuccessWithMissingPerson'

          message = personalizeTranslation.call(this, {
            key: localeKey,
            personType: otherMembersWithReport[0].personType,
            personGender: otherMembersWithReport[0].gender,
            options: { name: this.getFullNameFromPersonalDetails(otherMembersWithReport[0]) },
          })
        } else if (otherMembersWithReport.length > 1) {
          const names = otherMembersWithReport.map(personalDetails =>
            this.getFullNameFromPersonalDetails(personalDetails)
          )
          message = this.$t('signatureSuccessWithMissingPeople', { names: this.getListText(names) })
        } else {
          message = this.$t('signatureSuccessNoMissingPerson')
        }
      }

      this.addFlashSuccessMessage({
        key: `${SUCCESSFUL_SIGNATURE_URL_PARAM}${signedPersonKeysFromUrl}`,
        text: message,
        isAutoHiding: false,
      })
      removeQueryFromCurrentRoute(SUCCESSFUL_SIGNATURE_URL_PARAM)
    },

    showWarnMessageAfterForceReload() {
      if (getQueryFromUrl(MESSAGE_ON_LOAD_URL_PARAM) !== MESSAGE_ON_LOAD_IDS.errorForceReload) {
        return true
      }
      if (!this.isUserLoggedIn) {
        this.addFlashWarnMessage({
          key: `${MESSAGE_ON_LOAD_URL_PARAM}-${MESSAGE_ON_LOAD_IDS.errorForceReload}`,
          text: this.$t('errorForceReload'),
        })
      }
      removeQueryFromCurrentRoute(MESSAGE_ON_LOAD_URL_PARAM)
    },
  },
}
</script>

<style lang="scss" scoped>
.layout {
  background-color: $color-background;
}
</style>

<style lang="scss">
@import './default';
</style>

<i18n src="@/locales/default-layout.json" />
