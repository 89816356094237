// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".homeSteps_1R4Ut{background-color:#fff;text-align:center;width:100%;padding-bottom:calc(9.237vw + 80px)}.homeSteps_1R4Ut .headline_FB5YU{max-width:700px;margin:0 auto;padding:100px 30px 60px;box-sizing:content-box}@media only screen and (min-width:450px){.homeSteps_1R4Ut .headline_FB5YU{padding-left:60px;padding-right:60px}}@media only screen and (min-width:800px){.homeSteps_1R4Ut .headline_FB5YU{padding:calc(2vw + 90px) 80px 60px}}@media only screen and (min-width:950px){.homeSteps_1R4Ut .headline_FB5YU{padding-bottom:70px}}@media only screen and (min-width:1150px){.homeSteps_1R4Ut .headline_FB5YU{padding-top:100px}}.stepBlockWrap_3k9N_{display:flex;justify-content:center;flex-wrap:wrap;padding:0 20px}@media only screen and (min-width:950px){.stepBlockWrap_3k9N_{flex-wrap:nowrap}}.stepBlock_1pXBF{position:relative;margin:170px 20px 20px;width:280px;border-radius:6px;color:#242b59}@media only screen and (min-width:600px){.stepBlock_1pXBF{flex:0 0 50%;max-width:280px}}@media only screen and (min-width:950px){.stepBlock_1pXBF{flex:1}}.bgYellow_pPCGr{background-color:#fcde8f}.bgGreen_3R1mu{background-color:#b8ded1}.bgBlue_1aakX{background-color:#c9d9e3}.stepIcon_3u5pW{position:absolute;top:35px;left:calc(50% + 4px);width:194px;transform:translate(-50%,-100%)}.stepIcon--1_28dyA{top:45px;height:194px}.stepIcon--2_3Pp2W{height:198px}.stepIcon--3_cl3JZ{height:201px}.stepIndex_Aam44{display:flex;position:absolute;top:72px;left:50%;width:52px;height:52px;border-radius:50%;border:2px solid #fff;box-sizing:content-box;transform:translateX(-50%)}.stepIndexNumber_1FR2K{margin:auto;line-height:1;font-weight:700;font-size:36px;color:#fff}.stepBlock_1pXBF .stepTitle_wjgOq{margin:160px 0 20px;padding:0 25px}.stepText_1PI_H{height:140px;padding:0 30px 30px;box-sizing:content-box}.stepText--1_2P_Yb{padding-left:35px;padding-right:35px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colorSlant": "#f8f5f3",
	"homeSteps": "homeSteps_1R4Ut",
	"headline": "headline_FB5YU",
	"stepBlockWrap": "stepBlockWrap_3k9N_",
	"stepBlock": "stepBlock_1pXBF",
	"bgYellow": "bgYellow_pPCGr",
	"bgGreen": "bgGreen_3R1mu",
	"bgBlue": "bgBlue_1aakX",
	"stepIcon": "stepIcon_3u5pW",
	"stepIcon--1": "stepIcon--1_28dyA",
	"stepIcon--2": "stepIcon--2_3Pp2W",
	"stepIcon--3": "stepIcon--3_cl3JZ",
	"stepIndex": "stepIndex_Aam44",
	"stepIndexNumber": "stepIndexNumber_1FR2K",
	"stepTitle": "stepTitle_wjgOq",
	"stepText": "stepText_1PI_H",
	"stepText--1": "stepText--1_2P_Yb"
};
module.exports = ___CSS_LOADER_EXPORT___;
